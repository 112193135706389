import React from 'react';

const MonitorLibraryMgrContainer = Loader.loadBusinessComponent('MonitorLibrary','MonitorLibraryMgrContainer');

const outSiderMonitorLibMgr = () => {
  const sceneCode = BaseStore.user.appInfo.sceneCode;
  const monitorLabel = Dict.map.monitorLabel[sceneCode];
  const libTypeInfo = {
    libType: 2,
    libLabel: monitorLabel.outsider.libLabel,
    libPeople: monitorLabel.outsider.libPeople,
    moduleName: 'outsiderLibraryView',
  }
  return (
    <MonitorLibraryMgrContainer 
      libTypeInfo={libTypeInfo}
    />
  )
}

export default outSiderMonitorLibMgr;

